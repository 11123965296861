<template>
  <v-card
    height="100%"
    color="backgroundColor"
  >
    <EtablissementsTab />
  </v-card>
</template>

<script>
import EtablissementsTab from "../components/EtablissementComponents/EtablissementsTab.vue";

export default {
  components: {
    EtablissementsTab,
  },
};
</script>